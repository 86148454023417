import React from 'react'

export default function blogpage() {
  return (
    <div>
<section class=" pb-16 lg:pb-24 bg-white" style={{fontFamily:'poppins'}}>
  <div class="h-64 lg:h-144">
    <img class="w-full h-full object-cover" src="https://images.unsplash.com/photo-1458080767772-b1011d305557?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1043&amp;q=80" alt=""/>
  </div>
  <div class="relative container px-4 mx-auto -mt-24">
    <div class="max-w-5xl px-4 pt-12 lg:pt-20 mx-auto bg-gray-100">
      <div class="max-w-2xl mb-12 mx-auto text-center">
        <div class="flex items-center justify-center mb-6 text-xs text-blue-500 font-semibold uppercase">
          <a href="#">Home</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Blog</a>
          <span class="inline-block mx-2">
            <svg class="text-blue-500 h-3 w-3" viewbox="0 0 8 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.875 0L0 1.875L4.125 6L0 10.125L1.875 12L7.875 6L1.875 0Z"></path>
            </svg>
          </span>
          <a href="#">Article</a>
        </div>
        <h2 class="mb-6 text-3xl lg:text-5xl text-indigo-800 font-bold font-heading">Lorem ipsum dolor sit amet consectutar</h2>
        <div class="flex items-center justify-center">
          <div class="mr-6">
            <img class="w-14 h-14 object-cover" src="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt=""/>
          </div>
          <div class="text-left">
            {/* <h3 class="mb-2 text-lg text-gray-500 font-semibold font-heading">Sara Sampton</h3> */}
            <p class="text-blue-500 text-xs">Published On May 13, 2022</p>
          </div>
        </div>
      </div>
      <div class="max-w-2xl mx-auto -mb-6">
        <p class="mb-6  text-md lg:text-lg text-gray-500">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cumque eveniet est laborum exercitationem!</p>
        <p class="mb-6  text-md lg:text-lg text-gray-500">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor illo ipsa magni dolore doloribus? Velit laboriosam est nostrum ducimus, amet enim sed quidem excepturi?</p>
        <p class="mb-6  text-md lg:text-lg text-gray-500">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aspernatur aperiam ipsum vel facilis, quibusdam velit. Et, ipsam repellat? Possimus id exercitationem aut voluptatum cumque, nemo quaerat officiis accusamus.</p>
        <p class="mb-6  text-md lg:text-lg text-gray-500">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor illo ipsa magni dolore doloribus? Velit laboriosam est nostrum ducimus, amet enim sed quidem excepturi?</p>
      </div>
    </div>
  </div>
</section>



    </div>
  )
}
